import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import {
  ICreatePlacemarkColumns,
  IEditPlacemarkColumns,
  IPlacemarkColumns,
  IPlacemarkColumnsType,
  IPlacemarkColumnTitles,
  IPlacemarkColumnValues,
} from "src/models/IPlacemarkColumns";

export const getPlacemarkColumns = createAsyncThunk(
  "get-placemark-columns",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumns>(
        `${BASE_URL}/placemark-columns`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getPlacemarkColumnTitles = createAsyncThunk(
  "get-placemark-column-titles",
  async (params: Record<string, string>, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumnTitles>(
        `${BASE_URL}/placemark-columns/titles`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params,
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getPlacemarkColumnValues = createAsyncThunk(
  "get-placemark-column-values",
  async (params: Record<string, string>, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumnValues>(
        `${BASE_URL}/placemark-columns/values`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params,
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getPlacemarkColumnsType = createAsyncThunk(
  "get-placemark-columns-type",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumnsType>(
        `${BASE_URL}/placemark-columns/data-types`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createPlacemarkColumns = createAsyncThunk(
  "create-placemark-columns",
  async (formData: ICreatePlacemarkColumns, thunkAPI) => {
    try {
      const response = await axios.post<ICreatePlacemarkColumns>(
        `${BASE_URL}/placemark-columns`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSinglePlacemarkColumn = createAsyncThunk(
  "get-single-placemark-column",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumns>(
        `${BASE_URL}/placemark-columns/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editPlacemarkColumns = createAsyncThunk(
  "edit-placemark-columns",
  async (formData: IEditPlacemarkColumns, thunkAPI) => {
    try {
      const response = await axios.put<IEditPlacemarkColumns>(
        `${BASE_URL}/placemark-columns/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deletePlacemarkColumns = createAsyncThunk(
  "delete-placemark-columns",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IPlacemarkColumns>(
        `${BASE_URL}/placemark-columns/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
