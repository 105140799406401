import i18n from "src/i18n";
import { create } from "zustand";
import { persist } from 'zustand/middleware'

type Language = 'ru' | 'en'

type LanguageStore = {
    language: Language | null,
    updateLanguage: (lan: Language) => void
}

export const useLanguage = create<LanguageStore>()(
    persist(
      (set) => ({
        language: null,
        updateLanguage: (language: Language) => {
            set({language});
            i18n.changeLanguage(language)
        },
      }),
      {
        name: 'language'
      }
))