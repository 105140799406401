import { notification } from "antd";

const handleReduxError = (payload: any) => {
  let payloadMessage = {
    title: 'Ошибка',
    detail: 'Произошла ошибка'
  };
  try {
   
    if (Array.isArray(payload)) {
      payload?.map((item) => {
        payloadMessage = {...payloadMessage, title: item?.title, detail: item?.detail}
      })
  
      notification.open({
        type: 'error',
        message: payloadMessage.title,
        description: payloadMessage.detail
      })
      return;
    }
    
  } catch (error) {
    console.log(error)
  }

  try {
    if (payload) {
      notification.open({
        type: 'error',
        message: 'Произошла ошибка',
        description: payload ?? 'Ошибка'
      }); 
    }
  } catch (error) {
    console.log('handleError', error)
  }
};

export default handleReduxError;
