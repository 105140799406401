import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IisochroneToolsState } from "./isochroneToolsInterface";
import { IisochroneTools } from "src/models/IisochroneTools";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllIsochroneTools,
  getSingleIsochroneTools,
  createIsochroneTools,
  editIsochroneTools,
  deleteIsochroneTools
 } from "./isochroneToolsAction";

const initialState: IisochroneToolsState = {
  createIsochroneToolsLoading: false,
  createIsochroneToolsSuccess: false,
  createIsochroneToolsError: false,
  createdIsochroneTools: {},

  getAllIsochroneToolsLoading: false,
  getAllIsochroneToolsSuccess: false,
  getAllIsochroneToolsError: false,
  allIsochroneTools:{},

  getSingleIsochroneToolsLoading: false,
  getSingleIsochroneToolsSuccess: false,
  getSingleIsochroneToolsError: false,
  singleIsochroneTools: {},

  editIsochroneToolsLoading: false,
  editIsochroneToolsSuccess: false,
  editIsochroneToolsError: false,

  deleteIsochroneToolsLoading: false,
  deleteIsochroneToolsSuccess: false,
  deleteIsochroneToolsError: false,
};

const isochroneToolsSlice = createSlice({
  name: "isochroneTools",
  initialState,
  reducers: {
    cleanIsochroneTools: (state) => {
      state.createIsochroneToolsLoading =false;
      state.createIsochroneToolsSuccess =false;
      state.createIsochroneToolsError =false;

      state.editIsochroneToolsLoading=false;
      state.editIsochroneToolsSuccess=false;
      state.editIsochroneToolsError=false;

      state.deleteIsochroneToolsLoading=false;
      state.deleteIsochroneToolsSuccess=false;
      state.deleteIsochroneToolsError=false;

      state.getSingleIsochroneToolsLoading=false;
      state.getSingleIsochroneToolsSuccess=false;
      state.getSingleIsochroneToolsError=false;
    }
  },
  extraReducers: {
    [getAllIsochroneTools.fulfilled.type]: (state, action: PayloadAction<IisochroneTools>) => {
      state.getAllIsochroneToolsLoading = false;
      state.getAllIsochroneToolsError = false;
      state.getAllIsochroneToolsSuccess = true;
      state.allIsochroneTools = action.payload;
    },
    [getAllIsochroneTools.pending.type]: (state) => {
      state.getAllIsochroneToolsLoading = true;
    },
    [getAllIsochroneTools.rejected.type]: (state, action: PayloadAction) => {
      state.getAllIsochroneToolsLoading = false;
      state.getAllIsochroneToolsError = true;
      handleReduxError(action.payload);
    },
    [getSingleIsochroneTools.fulfilled.type]: (state, action: PayloadAction<IisochroneTools>) => {
      state.getSingleIsochroneToolsLoading = false;
      state.getSingleIsochroneToolsError = false;
      state.getSingleIsochroneToolsSuccess = true;
      state.singleIsochroneTools = action.payload;
    },
    [getSingleIsochroneTools.pending.type]: (state) => {
      state.getSingleIsochroneToolsLoading = true;
    },
    [getSingleIsochroneTools.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleIsochroneToolsLoading = false;
      state.getSingleIsochroneToolsError = true;
      handleReduxError(action.payload);
    },
    [createIsochroneTools.fulfilled.type]: (state, action: PayloadAction<IisochroneTools>) => {
      state.createIsochroneToolsLoading = false;
      state.createIsochroneToolsError = false;
      state.createIsochroneToolsSuccess = true;
      state.createdIsochroneTools = action.payload;
      state.deleteIsochroneToolsSuccess = false;
      toast.success('Зона доступности успешно создана!');
    },
    [createIsochroneTools.pending.type]: (state) => {
      state.createIsochroneToolsLoading = true;
    },
    [createIsochroneTools.rejected.type]: (state, action: PayloadAction) => {
      state.createIsochroneToolsLoading = false;
      state.createIsochroneToolsError = true;
      handleReduxError(action.payload);
    },
    [editIsochroneTools.fulfilled.type]: (state) => {
      state.editIsochroneToolsLoading = false;
      state.editIsochroneToolsError = false;
      state.editIsochroneToolsSuccess = true;
      toast.success('Зона доступности успешно отредактирована!');
    },
    [editIsochroneTools.pending.type]: (state) => {
      state.editIsochroneToolsLoading = true;
    },
    [editIsochroneTools.rejected.type]: (state, action: PayloadAction) => {
      state.editIsochroneToolsLoading = false;
      state.editIsochroneToolsError = true;
      handleReduxError(action.payload);
    },
    [deleteIsochroneTools.fulfilled.type]: (state) => {
      state.deleteIsochroneToolsLoading = false;
      state.deleteIsochroneToolsError = false;
      state.deleteIsochroneToolsSuccess = true;
    },
    [deleteIsochroneTools.pending.type]: (state) => {
      state.deleteIsochroneToolsLoading = true;
    },
    [deleteIsochroneTools.rejected.type]: (state, action: PayloadAction) => {
      state.deleteIsochroneToolsLoading = false;
      state.deleteIsochroneToolsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanIsochroneTools } = isochroneToolsSlice.actions;

export default isochroneToolsSlice.reducer;
