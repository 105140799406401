import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from 'src/configs';
import { ILayers, ICreateLayers, ICreateLayerFromFile, IEditLayers } from 'src/models/ILayers';


export const getAllLayers = createAsyncThunk(
  'get-all-layers',
  async (id:string | null | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ILayers>(`${BASE_URL}/layers`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
        params: {
          'filter[workspace-uuid]':id,
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getNonFilteredLayers = createAsyncThunk(
  'get-non=filtered-layers',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ILayers>(`${BASE_URL}/layers`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getFilteredLayers = createAsyncThunk(
  'get-all-layers-filtered',
  async (requestData:any, thunkAPI) => {
    try {
      const response = await axios.get<ILayers>(`${BASE_URL}/layers`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
        params: { 
          'filter[workspace-uuid]':requestData.id,
          'filter[layer-group-uuid]':requestData.layerGroupId,
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createLayer = createAsyncThunk(
  'create-layer',
  async (formData:ICreateLayers, thunkAPI) => {
    try {
      const response = await axios.post<ILayers>(`${BASE_URL}/layers`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSingleLayer = createAsyncThunk(
  'get-single-layer',
  async (id:string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ILayers>(`${BASE_URL}/layers/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editLayer = createAsyncThunk(
  'edit-layer',
  async (formData:IEditLayers, thunkAPI) => {
    try {          
      const response = await axios.put<IEditLayers>(`${BASE_URL}/layers/${formData.id}`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deleteLayer  = createAsyncThunk(
  'delete-layer',
  async (id:string, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/layers/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createLayerFromFile = createAsyncThunk(
  'create-layer-from-file',
  async (formData:ICreateLayerFromFile, thunkAPI) => {

    const data:any = new FormData();
    data.append('file', formData.file);

    try {
      const response = await axios.post<ILayers>(`${BASE_URL}/layers/file?workspace_uuid=${formData.workspaceId}`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getLayersTypes = createAsyncThunk(
  'get-layers-type',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ILayers>(`${BASE_URL}/layers/types`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
