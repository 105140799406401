import axios from "axios";
import {
  IPolygons,
  ICreatePolygons,
  IEditPolygons,
} from "../../../models/IPolygons";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllPolygons = createAsyncThunk(
  "get-all-polygons",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPolygons>(`${BASE_URL}/polygons`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          "filter[layer-uuid]": id,
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createPolygons = createAsyncThunk(
  "create-polygons",
  async (formData: ICreatePolygons, thunkAPI) => {
    try {
      const response = await axios.post<ICreatePolygons>(
        `${BASE_URL}/polygons`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSinglePolygon = createAsyncThunk(
  "get-single-polygon",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPolygons>(
        `${BASE_URL}/polygons/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editPolygons = createAsyncThunk(
  "edit-polygons",
  async (formData: IEditPolygons, thunkAPI) => {
    try {
      const response = await axios.put<IEditPolygons>(
        `${BASE_URL}/polygons/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deletePolygons = createAsyncThunk(
  "delete-polygons",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IPolygons>(
        `${BASE_URL}/polygons/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
