import axios from "axios";
import {
  IPolygonColumnGroups,
  IEditPolygonColumnGroups,
  ICreatePolygonColumnGroups
} from "src/models/IPolygonColumnGroups";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getPolygonColumnGroups = createAsyncThunk(
  "get-polygon-column-groups",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonColumnGroups>(
        `${BASE_URL}/polygon-column-groups`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            "filter[polygon-uuid]": id,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createPolygonColumnGroups = createAsyncThunk(
  "create-polygon-column-groups",
  async (data: ICreatePolygonColumnGroups, thunkAPI) => {
    try {
      const response = await axios.post<ICreatePolygonColumnGroups>(
        `${BASE_URL}/polygon-column-groups`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Create polygons column-groups error!");
    }
  }
);

export const getSinglePolygonColumnGroup = createAsyncThunk(
  "get-single-polygon-column-group",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonColumnGroups>(
        `${BASE_URL}/polygon-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editPolygonColumnGroups = createAsyncThunk(
  "edit-polygon-column-groups",
  async (formData: IEditPolygonColumnGroups, thunkAPI) => {
    try {
      const response = await axios.put<IEditPolygonColumnGroups>(
        `${BASE_URL}/polygon-column-groups/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deletePolygonColumnGroups = createAsyncThunk(
  "delete-polygon-column-groups",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IPolygonColumnGroups>(
        `${BASE_URL}/polygon-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
