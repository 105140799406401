import axios from "axios";
import { ILineStrings, IEditLineStrings, ICreateLineStrings} from "src/models/ILineStrings";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllLineStrings = createAsyncThunk(
  "get-all-lineStrings",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ILineStrings>(
        `${BASE_URL}/linestrings`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            "filter[layer-uuid]": id,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createLineStrings = createAsyncThunk(
  "create-lineStrings",
  async (formData: ICreateLineStrings, thunkAPI) => {
    try {
      const response = await axios.post<ICreateLineStrings>(
        `${BASE_URL}/linestrings`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSingleLineStrings = createAsyncThunk(
  "get-single-lineStrings",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ILineStrings>(
        `${BASE_URL}/linestrings/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editLineStrings = createAsyncThunk(
  "edit-lineStrings",
  async (formData: IEditLineStrings, thunkAPI) => {
    try {
      const response = await axios.put<IEditLineStrings>(
        `${BASE_URL}/linestrings/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deleteLineStrings = createAsyncThunk(
  "delete-lineStrings",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<ILineStrings>(
        `${BASE_URL}/linestrings/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
