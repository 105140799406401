import axios from 'axios';
export const BASE_URL = process.env.REACT_APP_API_KEY;

if (!process.env.REACT_APP_API_KEY && process.env.NODE_ENV === "development") {
  console.error('🥵🥵🥵 Создайте .env файл с методом для API запросов. Пример в .env.example')  
}

export default axios.create({ baseURL: BASE_URL });

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});