import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LayerPermissionList,
  LayerPermissionState,
} from "./layerPermissionInterface";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getLayerPermissions,
  getLayerPermissionsById,
  createLayerPermissions,
  editLayerPermissions,
  deleteLayerPermissions,
} from "./layerPermissionAction";

const initialState: LayerPermissionState = {
  getLayerPermissionsLoading: false,
  getLayerPermissionsSuccess: false,
  getLayerPermissionsError: false,
  allLayerPermissions: null,

  createLayerPermissionsLoading: false,
  createLayerPermissionsSuccess: false,
  createLayerPermissionsError: false,

  getLayerPermissionsByIdLoading: false,
  getLayerPermissionsByIdSuccess: false,
  getLayerPermissionsByIdError: false,
  singleLayer: {},

  editLayerPermissionsLoading: false,
  editLayerPermissionsSuccess: false,
  editLayerPermissionsError: false,

  deleteLayerPermissionsLoading: false,
  deleteLayerPermissionsSuccess: false,
  deleteLayerPermissionsError: false,
};

const layerPermissionSlice = createSlice({
  name: "layerPermissions",
  initialState,
  reducers: {
    cleanLayerPermission: (state) => {
      state.createLayerPermissionsLoading = false;
      state.createLayerPermissionsSuccess = false;
      state.createLayerPermissionsError = false;

      state.editLayerPermissionsLoading = false;
      state.editLayerPermissionsSuccess = false;
      state.editLayerPermissionsError = false;

      state.deleteLayerPermissionsLoading = false;
      state.deleteLayerPermissionsSuccess = false;
      state.deleteLayerPermissionsError = false;
    },
  },
  extraReducers: {
    [getLayerPermissions.fulfilled.type]: (
      state,
      action: PayloadAction<LayerPermissionList>
    ) => {
      state.getLayerPermissionsLoading = false;
      state.getLayerPermissionsError = false;
      state.allLayerPermissions = action.payload;
      state.getLayerPermissionsSuccess = true;
    },
    [getLayerPermissions.pending.type]: (state) => {
      state.getLayerPermissionsLoading = true;
    },
    [getLayerPermissions.rejected.type]: (state, action: PayloadAction) => {
      state.getLayerPermissionsLoading = false;
      state.getLayerPermissionsError = true;
      // @ts-ignore
      console.log(action)
      handleReduxError(action.payload);
    },
    [createLayerPermissions.fulfilled.type]: (state) => {
      state.createLayerPermissionsLoading = false;
      state.createLayerPermissionsError = false;
      state.createLayerPermissionsSuccess = true;
      toast.success("Слой успешно создан!");
    },
    [createLayerPermissions.pending.type]: (state) => {
      state.createLayerPermissionsLoading = true;
    },
    [createLayerPermissions.rejected.type]: (state, action: PayloadAction) => {
      state.createLayerPermissionsLoading = false;
      state.createLayerPermissionsError = true;
      handleReduxError(action.payload);
    },
    [getLayerPermissionsById.fulfilled.type]: (
      state,
      action: PayloadAction<LayerPermissionList>
    ) => {
      state.getLayerPermissionsByIdLoading = false;
      state.getLayerPermissionsByIdError = false;
      state.singleLayer = action.payload;
      state.getLayerPermissionsByIdSuccess = true;
    },
    [getLayerPermissionsById.pending.type]: (state) => {
      state.getLayerPermissionsByIdLoading = true;
    },
    [getLayerPermissionsById.rejected.type]: (state, action: PayloadAction) => {
      state.getLayerPermissionsByIdLoading = false;
      state.getLayerPermissionsByIdError = true;
      handleReduxError(action.payload);
    },
    [editLayerPermissions.fulfilled.type]: (state) => {
      state.editLayerPermissionsLoading = false;
      state.editLayerPermissionsError = false;
      state.editLayerPermissionsSuccess = true;
      toast.success("Слой успешно обновлен!");
    },
    [editLayerPermissions.pending.type]: (state) => {
      state.editLayerPermissionsLoading = true;
    },
    [editLayerPermissions.rejected.type]: (state, action: PayloadAction) => {
      state.editLayerPermissionsLoading = false;
      state.editLayerPermissionsError = true;
      handleReduxError(action.payload);
    },
    [deleteLayerPermissions.fulfilled.type]: (state) => {
      state.deleteLayerPermissionsLoading = false;
      state.deleteLayerPermissionsError = false;
      state.deleteLayerPermissionsSuccess = true;
      toast.success("Слой успешно удален!");
    },
    [deleteLayerPermissions.pending.type]: (state) => {
      state.deleteLayerPermissionsLoading = true;
    },
    [deleteLayerPermissions.rejected.type]: (state, action: PayloadAction) => {
      state.deleteLayerPermissionsLoading = false;
      state.deleteLayerPermissionsError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanLayerPermission } = layerPermissionSlice.actions;

export default layerPermissionSlice.reducer;
