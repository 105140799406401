import axios from "axios";
import {
  IPolygonTools,
  IAttributes,
  IEditPolygonTools,
} from "src/models/IPolygonTools";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllPolygonTools = createAsyncThunk(
  "get-all-polygon-tools",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonTools>(
        `${BASE_URL}/polygon-tools`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createPolygonTools = createAsyncThunk(
  "create-polygon-tools",
  async (formData: IAttributes, thunkAPI) => {
    try {
      const response = await axios.post<IPolygonTools>(
        `${BASE_URL}/polygon-tools`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSinglePolygonTools = createAsyncThunk(
  "get-single-polygon-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonTools>(
        `${BASE_URL}/polygon-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editPolygonTools = createAsyncThunk(
  "edit-polygon-tools",
  async (formData: IEditPolygonTools, thunkAPI) => {
    try {
      const response = await axios.put<IEditPolygonTools>(
        `${BASE_URL}/polygon-tools/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deletePolygonTools = createAsyncThunk(
  "delete-polygon-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.delete<IPolygonTools>(
        `${BASE_URL}/polygon-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
