import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import {
  IisochroneTools,
  IEditIsochroneTools,
} from "src/models/IisochroneTools";

export const getAllIsochroneTools = createAsyncThunk(
  "get-all-isochrone-tools",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IisochroneTools>(
        `${BASE_URL}/isochrone-tools`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSingleIsochroneTools = createAsyncThunk(
  "get-single-isochrone-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IisochroneTools>(
        `${BASE_URL}/isochrone-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editIsochroneTools = createAsyncThunk(
  "edit-isochrone-tools",
  async (data: IEditIsochroneTools, thunkAPI) => {
    try {
      const response = await axios.put<IEditIsochroneTools>(
        `${BASE_URL}/isochrone-tools/${data.id}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createIsochroneTools = createAsyncThunk(
  "create-isochrone-tools",
  async (data: IisochroneTools, thunkAPI) => {
    try {
      const response = await axios.post<IisochroneTools>(
        `${BASE_URL}/isochrone-tools`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deleteIsochroneTools = createAsyncThunk(
  "delete-isochrone-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/isochrone-tools/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
