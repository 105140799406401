import axios from "axios";
import {
  ICircleTools,
  IAttributes,
  IEditCircleTools,
} from "src/models/ICircleTools";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllCircleTools = createAsyncThunk(
  "get-all-circle-tools",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ICircleTools>(
        `${BASE_URL}/circle-tools`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createCircleTools = createAsyncThunk(
  "create-circle-tools",
  async (formData: IAttributes, thunkAPI) => {
    try {
      const response = await axios.post<ICircleTools>(
        `${BASE_URL}/circle-tools`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSingleCircleTools = createAsyncThunk(
  "get-single-circle-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ICircleTools>(
        `${BASE_URL}/circle-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editCircleTools = createAsyncThunk(
  "edit-circle-tools",
  async (formData: IEditCircleTools, thunkAPI) => {
    try {
      const response = await axios.put<IEditCircleTools>(
        `${BASE_URL}/circle-tools/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deleteCircleTools = createAsyncThunk(
  "delete-circle-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.delete<ICircleTools>(
        `${BASE_URL}/circle-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
