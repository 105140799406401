import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import { IGraphs, IEditGraphs, ICreateGraphs } from "src/models/IGraphs";

export const getAllFilteredGraphs = createAsyncThunk(
  "get-all-filtered-graphs",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IGraphs>(`${BASE_URL}/graphs`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          "filter[layer-uuid]": id,
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getAllGraphs = createAsyncThunk(
  "get-all-graphs",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGraphs>(`${BASE_URL}/graphs`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const getSingleGraph = createAsyncThunk(
  "get-single-graph",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IGraphs>(`${BASE_URL}/graphs/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const editGraphs = createAsyncThunk(
  "edit-graphs",
  async (data: IEditGraphs, thunkAPI) => {
    try {
      const response = await axios.put<IEditGraphs>(
        `${BASE_URL}/graphs/${data.id}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const createGraphs = createAsyncThunk(
  "create-graphs",
  async (data: ICreateGraphs, thunkAPI) => {
    try {
      const response = await axios.post<ICreateGraphs>(
        `${BASE_URL}/graphs`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);

export const deleteGraphs = createAsyncThunk(
  "delete-graphs",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/graphs/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.detail);
    }
  }
);
